<template>
    <div>
        <label class="text-caption text-label font-weight-bold text-label" v-text="label"></label><br>
        <p class="mb-0 text-title" v-text="text" v-if="!list"></p>
        <p class="mb-0 text-title" v-if="list">
            <slot name="list">
                <span class="mr-2" :key="index" v-for="(text, index) in texts" v-text="text"></span>
            </slot>
        </p>
    </div>
</template>


<script>

export default {
    name: "DisplayLabel",
    props: {
        list: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: true,
            default: "Label"
        },
        text: {
            type: String,
            required: false,
            default: ""
        },
        texts: {
            type: Array,
            required: false,
            default: () => []
        }
    }
}

</script>