<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-card>
                <v-toolbar dense color="primary" class="elevation-0 white--text">
                    <v-toolbar-title>Outlet Visiting Feedback Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="pa-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Shift Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Work Date" :text="object.shift.work_date"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Start Time" :text="object.shift.start_time"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="End Time" :text="object.shift.end_time"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Break Time (Hour)" :text="object.shift.break_time?object.shift.break_time.toString():''"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Total Hours" :text="object.shift.total_hours?object.shift.total_hours.toString():''"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Hourly Rate (S$)" :text="object.shift.hourly_rate?object.shift.hourly_rate.toString():''"></display-label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Promoter Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Full Name" :text="object.shift.account.name"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Mobile Number" :text="object.shift.account.mobile_number"></display-label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Feedback Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Visitor" :text="object.visiting.visitor"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Performance" :text="object.visiting.performance.text"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Crowed Feedback" :text="object.visiting.feedback.text"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Remarks" :text="object.visiting.remarks"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <display-label label="Photo Attachment(s)"></display-label>
                            <v-img max-width="50" class="mx-3 pointer" :key="index" :src="item.thumbnail_url" @click="openImageDialog(item)" v-for="(item, index) in object.visiting.images"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <image-dialog ref="imageDialog"></image-dialog>
	</div>
</template>


<script>

import DisplayLabel from '../../../../shared/DisplayLabel.vue'
import ImageDialog from '../../../../shared/ImageDialog.vue'

export default {
    name: 'outletVisitingFeedbackInfoDialog',
    components: {
        displayLabel: DisplayLabel,
        imageDialog: ImageDialog,
    },
    data() {
        return {
            valid: true,
            dialog: false,
            loading: false,
            object: {
                shift: {
                    account: {}
                },
                visiting: {
                    visitor: '',
                    performance: {},
                    feedback: {}
                },
            }
        }
    },
    computed: {
        imageDialog() {
            return this.$refs.imageDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.object = { shift: { account: {} }, visiting: { visitor: '', performance: {}, feedback: {}}}
        },
        updateObject: function(item) {
            this.object = item
        },
        openImageDialog: function(item) {
            this.imageDialog.updateImageUrl(item.image_url)
            this.imageDialog.open()
        }
    }
}

</script>


<style scoped>
/deep/ .text-label {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

</style>