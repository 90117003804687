<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Outlet Visiting Feedback Info</p>
                <p class="text-title mb-0">You do not have permission to view outlet visiting feedback info</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h4 mb-0" v-text="object.name"></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Client" :text="object.client.text"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
           <v-row v-if="permissions.can_list">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-menu offset-y transition="scale-transition" min-width="auto" color="primary" :close-on-content-click="false" v-model="workDateMenu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense clearable label="Date of Activation" v-bind="attrs" v-on="on" v-model="filters.work_date"></v-text-field>
                                    </template>
                                    <v-date-picker no-title color="primary" v-model="filters.work_date" @input="workDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="items.length > 0 && permissions.can_download">
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :disabled="loading" :ripple="false" @click="openDownloadDialog">
                        <v-icon small left>mdi-download</v-icon> Download PDF
                    </v-btn>
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :disabled="loading" :ripple="false" @click="openDownloadDialogExcel">
                        <v-icon small left>mdi-download</v-icon> Download Excel
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-img width="100" class="mx-auto my-3" :src="item.visiting.images[0].thumbnail_url" @click="openImageDialog(item.visiting.images[0].image_url)" v-if="item.visiting.images.length > 0"></v-img>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.shift.campaign.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.shift.outlet.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span class="d-block" v-text="item.shift.work_date"></span>
                                    <span v-text="item.shift.start_time"></span> - <span v-text="item.shift.end_time"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.shift.account.name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.shift.status.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.visiting.performance.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.visiting.feedback.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.visiting.visitor"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openInfoDialog(item)">
                                                <v-list-item-title>View Details</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <info-dialog ref="infoDialog"></info-dialog>
		<download-dialog ref="downloadDialog"></download-dialog>
        <download-excel-dialog ref="downloadExcelDialog"></download-excel-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../../shared/DisplayLabel.vue'
import OutletVisitingFeedbackDownloadDialog from './OutletVisitingFeedbackDownloadDialog'
import OutletVisitingFeedbackExcelDownloadDialog from './OutletVisitingFeedbackExcelDownloadDialog';
import OutletVisitingFeedbackInfoDialog from './OutletVisitingFeedbackInfoDialog'

export default {
	name: 'OutletVisitingFeedbackReportInfo',
	components: {
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        downloadDialog: OutletVisitingFeedbackDownloadDialog,
        downloadExcelDialog: OutletVisitingFeedbackExcelDownloadDialog,
        infoDialog: OutletVisitingFeedbackInfoDialog,
	},
	data () {
		return {
			loading: false,
			workDateMenu: null,
			selectedItems: {},
			selectedItemIds: [],
            filters: {
                work_date: '',
            },
			object: {
                client: {},
                name: '',
			},
            permissions: {
                can_list: false,
                can_download: false
            }
		}
	},
	computed: {
        infoDialog() {
            return this.$refs.infoDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        downloadExcelDialog() {
            return this.$refs.downloadExcelDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			const filters = { project: this.$route.params.id, work_date: this.filters.work_date?this.filters.work_date:'' }
			this.$store.dispatch('epanel/report/getReportOutletVisitingFeedbackInfo', filters).then((response) => {
                this.loading = false
                this.object = response.data.object
                this.headers = response.data.headers
                this.items = response.data.items
                this.permissions = response.data.permissions
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { project: this.$route.params.id, work_date: '', page: 1}
        },
		openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.updateObject({ work_date: this.filters.work_date })
                this.downloadDialog.open()
            }
        },
        openDownloadDialogExcel: function() {
            if(this.permissions.can_download) {
                this.downloadExcelDialog.updateObject({ work_date: this.filters.work_date })
                this.downloadExcelDialog.open()
            }
        },
        openInfoDialog: function(item) {
            this.infoDialog.updateObject(item)
            this.infoDialog.open()
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
	}
}

</script>