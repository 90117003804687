<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card>
            <v-card-title>
                <span v-text="title" v-if="title"></span>
                <v-spacer></v-spacer>
                <v-btn icon small @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <!-- <v-img contain :src="object.image_url" v-if="object.image_url"></v-img> -->
                <div v-if="object.image_url">
                    <img :src="object.image_url" style="object-fit: contain; max-width: 100%">
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ImageDialog',
    props: {
        title: {
            type: String,
            required: false,
            default: 'Preview Image'
        }
    },
    data() {
        return {
            dialog: false,
            object: {
                image_url: ''
            }
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        updateImageUrl: function(imageUrl) {
            this.object.image_url = imageUrl
        }
    }
}

</script>