<template>
    <v-snackbar top right :color="color" :timeout="timeout" v-model="show"><span v-text="text"></span></v-snackbar>
</template>


<script>

export default {
    name: 'MessageNotifier',
    props: {
        timeout: {
            type: Number,
            required: false,
            default: 3000
        },
        color: {
            type: String,
            required: false,
            default: 'success'
        }
    },
    data() {
        return {
            show: false,
            text: ''
        }
    },
    methods: {
        open: function() {
            this.show = true
        },
        close: function() {
            this.show = false
        },
        updateText: function(text) {
            this.text = text
        }
    }
}

</script>